import React, { useState } from 'react'


import Hero from '../components/hero'
import Carouseler from '../components/carouseler'

import Layout from "../components/layout"
import SEO from "../components/seo"

import Player from "../components/player"
import '../styles/leadership.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import Newlogo from '../assets/newlogo.svg';

import { 
  smoothScrollBackToTop, 
  getHero, 
  getCarousel, 
  COLORS
  } from '../utils/index.js'



  function handleClick(link, j){
    window.open(link, '_blank');
    if(window.analytics){
      window.analytics('send', 
        {
          hitType: 'event',
          eventCategory: 'insights',
          eventAction: 'click',
          eventLabel: 'carousel2' + j
        }
      );
    }
  }




const Insights = (props) => {
  const [show, setShow] = useState(-1);
  const hero = getHero()
  const carousel = getCarousel()

  return (
     <>
      <Layout>
        <SEO title="ESG Insights" description="At BNY Mellon, we’re committed to putting the Future FirstSM by using our global reach, influence, and resources not just to power success today, but to help safeguard the future."/>

        <div className="back-bg d-block" />
        <div className="back-bg2 d-block" />

        <div id="leadership" className="container-fluid bg-softblue">
          <div className="container">

            <div className="mb-5 d-none d-lg-block" />

            <div className="row pt-5 pb-5">
              
              <div className="col-lg-7">
                <h1 className="publico-text-web display-4 mb-3 color-blue">{`ESG Insights`}</h1>
                <p className="h5-small mb-0 color-blue">{`BNY Mellon taps wide-ranging expertise to share the latest thinking on environmental, social, and governance (ESG) principles and the growing awareness among market participants and regulatory stakeholders.`}</p>
              </div>

              <div className="col-lg-5 d-flex justify-content-end align-items-end">
                <a 
                  rel="noreferrer"
                  id="watch-intro" 
                  href="/pdf/F3-RecapArticle.PDF"
                  className="btn btn-darkteal btn-lg watch-intro medium"
                  onClick={(e)=>{
                    // e.preventDefault();
                  if(window.analytics){
                    window.analytics('send', 
                      {
                        hitType: 'event',
                        eventCategory: 'insights',
                        eventAction: 'click',
                        eventLabel: 'RECAP ARTICLE'
                      }
                    );
                  }
                  }}
                  target="_blank" 
                >RECAP ARTICLE</a>
              </div>
            </div>

            <Hero
              key={0}
              data={hero}
              colors={COLORS[3]} 
            />

            
            <div className={`row no-gutters mb-3`}>
              
              <div className={`col-12 col-md-6 mb-3 mb-md-0 pr-md-2`}
                  role="button"
                  tabIndex={0} 
                  data-id={0}
                  onKeyDown={()=>{
                    handleClick(`https://futurefirstforum.bnymellon.com/pdf/Powering%20a%20New%20Era%20of%20ESG%20(White%20Paper).PDF`, 0)
                  }}
                  onClick={()=>{
                    handleClick(`https://futurefirstforum.bnymellon.com/pdf/Powering%20a%20New%20Era%20of%20ESG%20(White%20Paper).PDF`, 0)
                  }}
              >
                <div 
                  className={`carousel-element carousel-element2`} 
                  style={{backgroundImage: `url(${`../images/heroes2/h1.jpg`})` }}
                >
                  <div className={`carousel-element-container car-49`}>
                    <div className={`p-2 p-sm-3 pt-2 pt-sm-4 pb-2 pb-sm-4 carousel-element-info`}>
                      <div className={`carousel-element-type d-flex align-items-center mb-1 mb-sm-2`}>
                        <div className={`carousel-element-type-dot`} />
                        <span className={`carousel-element-type-text akkurat-pro-bold ml-2`} >{ `Read` }</span>
                      </div>
                      <div className={`akkurat-pro h6 carousellertext mb-3`} dangerouslySetInnerHTML={{__html: `Powering a New Era of ESG` }} />
                    </div>
                  </div>

                </div>
              </div>

              <div className={`col-12 col-md-6 pl-md-2`}
                  role="button"
                  tabIndex={0} 
                  data-id={0}
                  onKeyDown={()=>{
                    handleClick(`https://www.bnymellon.com/us/en/about-us/esg-and-responsible-investment.html`, 1)
                  }}
                  onClick={()=>{
                    handleClick(`https://www.bnymellon.com/us/en/about-us/esg-and-responsible-investment.html`, 1)
                  }}
              >
                <div 
                  className={`carousel-element carousel-element2`} 
                  style={{backgroundImage: `url(${`../images/heroes2/h0.jpg`})` }}
                >
                  <div className={`carousel-element-container car-49`}>
                    <div className={`p-2 p-sm-3 pt-2 pt-sm-4 pb-2 pb-sm-4 carousel-element-info`}>
                      <div className={`carousel-element-type d-flex align-items-center mb-1 mb-sm-2`}>
                        <div className={`carousel-element-type-dot`} />
                        {/*
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{color: red}} size="xs"/>
                        */}

                        <span className={`carousel-element-type-text akkurat-pro-bold ml-2`} >{ `Learn more` }</span>
                      </div>
                      <div className={`akkurat-pro h6 carousellertext mb-3`} dangerouslySetInnerHTML={{__html: `Committed to Putting the Future First<sup>℠</sup> ` }} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
        


            <div className={`d-block d-md-none mb-5`}>
              <Carouseler data={carousel.slice()} chuckSize={2}/>
            </div>

            <div className={`d-none d-md-block mb-5`}>
              <Carouseler data={carousel.slice()} chuckSize={4}/>
            </div>

            <p className="publico-text-web display-4 mb-3 color-blue">{`Latest Developments`}</p>
            <p className="h5-small mb-0 color-blue">{`BNY Mellon’s recent initiatives in addressing today’s global challenges.`}</p>

            <a 
              rel="noreferrer"
              id="watch-intro" 
              href="https://www.bnymellon.com/us/en/about-us/newsroom/press-release/bny-mellon-and-world-economic-forum-partner-to-shape-the-future-of-capital-markets-130174.html?cid=CC_OSM_YB001_WorldEconomicForum_ALLSEG_PUB_GL_FB_____"
              className="btn btn-darkteal btn-lg watch-intro2 medium mr-0 mr-sm-3"
              onClick={(e)=>{
                // e.preventDefault();
                if(window.analytics){
                window.analytics('send', 
                  {
                    hitType: 'event',
                    eventCategory: 'insights',
                    eventAction: 'click',
                    eventLabel: 'world economic forum'
                  }
                );
              }
              }}
              target="_blank" 
            >World Economic Forum</a>

            <a 
              rel="noreferrer"
              id="watch-intro" 
              href="https://www.bnymellon.com/us/en/about-us/newsroom/company-news/bny-mellon-collaborates-with-yale-initiative-on-sustainable-finance-to-further-esg-research.html"
              className="btn btn-darkteal btn-lg watch-intro2 medium"
              onClick={(e)=>{
                // e.preventDefault();
                              if(window.analytics){
                window.analytics('send', 
                  {
                    hitType: 'event',
                    eventCategory: 'insights',
                    eventAction: 'click',
                    eventLabel: 'yale initiative on sustainable finance'
                  }
                );
              }
              }}
              target="_blank" 
            >Yale Initiative on Sustainable Finance</a>

            <div className="mb-3 mt-5 pt-5 position-relative">
              
              <div className="row no-gutters position-relative p-0 d-flex justify-content-start align-items-stretch"
                style={{ backgroundColor: `#f0f7f8`, color: `#00485E` }}
              >
                
                <div 
                  className={"col-12 col-md-6 p-4 d-flex flex-column"} 
                  style={{ backgroundColor: `#f0f7f8`, color: `#00485E` }}
                >
                  <div className="h6 accurat-pro-bold mt-0 mb-5" dangerouslySetInnerHTML={{__html: `Upcoming Events` }} />
            
                  <div className="mb-4 newlogo">
                    <Newlogo />
                  </div>

                  <div className="h6 mb-4" dangerouslySetInnerHTML={{__html: `Whatever your role, the luminaries on the virtual main stage and industry rock stars in the on-demand sessions will deliver content that will leave you ready to lead effectively, ready to think differently and ready to share new sources of inspiration. It’s all virtual and at your fingertips. On your schedule. And free. Join us June 8-10. You don’t want to miss this.` }} />
                  
                  <div className="mt-auto row no-gutters d-flex justify-content-start">
                      <a
                        rel="noreferrer"
                        href={"https://insite.bnymellon.com/home.html"} 
                        target="_blank"
                        className="play-calendar btn btn-darkteal btn-lg btn-block medium"

                        onClick={(e)=>{
                          if(window.analytics){
                            window.analytics('send', 
                              {
                                hitType: 'event',
                                eventCategory: 'insights pershing',
                                eventAction: 'click',
                                eventLabel: 'pershing view'
                              }
                            );
                          }
                        }}
                      >
                        {`Learn more `}
                        <FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/>
                      </a>
                  </div>

                </div>

                <div className={"col-12 col-md-6 p-4 d-flex justify-content-center align-items-center"} >
                    <img 
                      className={`new-image mt-4`} 
                      src={`../images/new@2x.png`} 
                      alt={`Upcoming Events`}
                    />
                </div>

              </div>
            </div>

            <div className="d-flex justify-content-center position-relative">
              <a 
                id="back-to-top" 
                href="/#" 
                className="btn btn-darkteal btn-lg back-to-top medium mt-17 mb-17 pl-5 pr-5"
                onClick={(e)=>{
                  e.preventDefault();
                  smoothScrollBackToTop();
                  if(window.analytics){
                    window.analytics('send', 
                      {
                        hitType: 'event',
                        eventCategory: 'insights',
                        eventAction: 'click',
                        eventLabel: 'totop'
                      }
                    );
                  }
                }}
              >
                Back to top
              </a>
            </div>
          </div>
        </div>

      </Layout>

      <Player UUID={show} setShow={setShow}/>
    </>
    
  )
}

export default Insights