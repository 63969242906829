import * as React from "react"
import "./carouseler.css"

import Carousel from 'react-bootstrap/Carousel'

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Video from '../assets/video.svg';
import Audio from '../assets/audio.svg';
import Read from '../assets/read.svg';




  function handleClick(link, j){
    window.open(link, '_blank');
    if(window.analytics){
      window.analytics('send', 
        {
          hitType: 'event',
          eventCategory: 'insights',
          eventAction: 'click',
          eventLabel: 'carousel' + j
        }
      );
    }
  }


const Carouseler = (props) => {

	const chuckSize = props.chuckSize;
	const dt = props.data;
	let cs = new Array(Math.ceil(dt.length / chuckSize)).fill().map(_ => dt.splice(0,chuckSize))

  return (
      <Carousel 
      	className="" 
      	controls={(cs.length>1)?true:false}
        indicators={false}
        interval={null} 
        slide={true}
        prevIcon={<FontAwesomeIcon color="#00485E" icon={faChevronLeft} size="lg"/>}
        nextIcon={<FontAwesomeIcon color="#00485E" icon={faChevronRight} size="lg"/>}
      >
          {
            cs.map((d,i)=>(
              <Carousel.Item key={i} className={``}>
                <div className="w-100 row mr-0 ml-0">
                  <div className="container no-gutters">
                    <div className="row carousel-row">
                      {
                        d.map((q,j)=>(

                          <div
                            role="button"
                            tabIndex={0} 
                            key={j} 
                            className={`carousel-element col-` + Math.round(12/chuckSize)} 
                            data-id={j}
                            onKeyDown={()=>{
                              handleClick(q.link, j)
                            }}
                            onClick={()=>{
                              handleClick(q.link, j)
                            }}
                          >
                            <div className="carousel-element-img"> 

    					            <img 
    					              key={`../images/carousel2/c${q.id}.jpg`} 
    					              className="img-fluid" 
    					              src={ `../images/carousel2/c${q.id}.jpg`} 
    					              alt={q.h1}
    					            />


                              <div className={`play-icon-container`}>
                                {
                                  (q.type==="Watch") ? <Video className={`play-icon`} /> : 
                                  ((q.type==="Listen") ? <Audio className={`play-icon`} /> : 
                                    <Read className={`play-icon`} /> )
                                }
                              </div>

                                {
                                  (q.type!=="Read")?
                                  <div className="player2" />
                                    :
                                  <div className="player2" />
                                }

                            </div>

                            <div className={`carousel-element-container`}>
                              <div className={`p-2 p-sm-3 pt-2 pt-sm-4 pb-2 pb-sm-4 carousel-element-info`}>
                                <div className={`carousel-element-type d-flex align-items-center mb-1 mb-sm-2`}>
                                  <div className={`carousel-element-type-dot`} />
                                  <span className={`carousel-element-type-text akkurat-pro-bold ml-2`} >{ q.type }</span>
                                </div>
                                <div className={`akkurat-pro h6 carousellertext mb-3`} dangerouslySetInnerHTML={{__html: q.h1 }} />
                                

                                {/*

                                <div className={`dop-info`}>
                                  <div className={`h6 mb-3`} dangerouslySetInnerHTML={{__html: q.text }} />
                                  <div className={`pl-3 carousel-speakers`}>
                                    {
                                      q.speakersName.map((w,k)=>(
                                        <div key={k} className={``}>
                                            <div className={`akkurat-pro-bold h6 small`}>{w.name + ` ` + w.surname}</div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                </div>
                                  
                                */}

                              </div>
                            </div>
                          </div>
                     
                        ))
                      }


                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))
          }
      </Carousel>
  )
}

export default Carouseler