import React from "react"
import './hero.css'

const Hero = (props) => {

  const data = props.data;
  const colors = props.colors;
  const id = data.id;

  return (
      <div 
        className="hero mb-3 position-relative"
        style={{backgroundImage: `url(${`../images/heroes/h${id}.jpg`})` }}
      >

          <div className="row no-gutters d-block d-sm-none w-100"> 
            <img 
              className="img-fluid" 
              src={`../images/heroes/h${id}.jpg`} 
              alt={data.h1}
            />
          </div>

          <div className="hero-container row no-gutters position-relative p-0 p-sm-5 d-flex justify-content-end align-items-stretch">

            <div 
              className={"col-md-6 col-sm-9 p-4 d-flex flex-column"} 
              style={{ backgroundColor: `${colors.bgColor}`, color: `${colors.textColor}` }}
            >
              <div className="h1 mt-0 mt-lg-5 mb-4 publico-text-web" dangerouslySetInnerHTML={{__html: data.h0 }} />
              <div className="h2 mb-4 publico-text-web" dangerouslySetInnerHTML={{__html: data.h1 }} />
              <div className="h6 mb-5" dangerouslySetInnerHTML={{__html: data.text }} />
              <div className="mt-auto row no-gutters d-flex justify-content-end">
                
                  <a
                    rel="noreferrer"
                    href={"/pdf/Future First Overview.PDF"} 
                    target="_blank"
                    className="play-calendar btn btn-darkteal btn-lg btn-block medium"

                    onClick={(e)=>{

                    if(window.analytics){
                      window.analytics('send', 
                        {
                          hitType: 'event',
                          eventCategory: 'insights',
                          eventAction: 'click',
                          eventLabel: 'pdf view'
                        }
                      );
                    }
                    }}

                  >
                    {`View`}
                  </a>
              </div>
            </div>
          </div>

      </div>
  )
}

export default Hero